import { Link } from "gatsby";
import React from "react";

const PostLink = ({ post }) => (
  <article className="pb-4">
    <time dateTime={post.frontmatter.date} className="block">
      {post.frontmatter.date}
    </time>
    <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
  </article>
);

export default PostLink;
